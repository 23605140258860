// Variables
$color-green: #6c3
$color-blue: darken(#09f, 5%)
//$color-blue: #3A5683
$color-darkgreen: #63aa32
$color-red: #ff595e
$color-yellow: #f4f1bb
$color-white: #fff
$font-title: 'Signika'
$font-text: 'Public Sans'
$font-monospace: 'JetBrains Mono', monospace
