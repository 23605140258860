@font-face
  font-family: $font-title
  src: url(/fonts/signika/signika-regular.woff2)

@font-face
  font-family: $font-text
  src: url(/fonts/public-sans.woff2)

@font-face
  font-family: $font-monospace
  src: url(/fonts/jetbrainsmono-regular.woff2)
