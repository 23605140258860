.main
  .introduction
    &__grid
      display: grid
      grid-gap: 3rem
      grid-template: repeat(4, auto) / 1fr 1fr 10fr
      margin: 3rem 0
    &__title
      margin: 0
    &__content-image
      grid-row: 1 / 5
      grid-column: 1 / 3
      display: flex
      justify-content: flex-end
    &__image
      max-width: 10rem
    &__text-1
      grid-row: 1 / 2
      grid-column: 3 / 4
    &__text-2
      grid-row: 2 / 3
      grid-column: 3 / 4
      display: flex
      flex-direction: column
      justify-content: center
    &__text-3
      grid-row: 3 / 4
      grid-column: 3 / 4
      display: flex
      flex-direction: column
      justify-content: center
    &__text-4
      grid-row: 4 / 5
      grid-column: 3 / 4
      display: flex
      flex-direction: column
      justify-content: flex-end
    &__text--tab
      margin-left: 3rem
  &__grid
    display: grid
    grid-template-columns: 15rem auto
  &__title
    margin-bottom: 4rem
    color: $color-green
  .github
    display: flex
    justify-content: flex-end
    align-items: center
    &__icon
      width: 2rem
      margin-right: 1rem
  .mark
    height: 2rem
  .container
    margin-top: 2rem
    margin-botom: 5rem
  h2
    font-weight: normal
    margin: 3rem 0
    line-height: 2rem
  .success__stories
    margin-bottom: 6rem
    .main__title
      margin-bottom: 0
    p
      text-align: center
    img
      margin-top: 3rem
      max-width: 10rem
