html
  scroll-behavior: smooth

body
  background-color: $color-white
  font-family: $font-text

h1, h2, h3, h4, h5, h6
  font-family: $font-title

pre
  display: grid

code
  font-family: $font-monospace !important
  padding: 1.5rem !important
  font-size: 1rem !important

a
  color: $color-green
  &:hover
    text-decoration: underline

table
  td, th
    border-color: $color-green
  a
    color: $color-green
    &:hover
      text-decoration: underline

.container
  max-width: 1000px
  margin: 0 auto
  padding: 0 1rem

.container--full
    max-width: 100%

.talks__content
  display: none
